<template>
    <BT-Blade-Items
        addBladeName="standing-customer-order"
        bladeName="standing-customer-orders"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :getNewBladeData="getNewBladeData"
        :getSelectBladeData="getSelectBladeData"
        :getParams="item => { return { includeDetails: false, customerIDs: item.data.customerID }}"
        :headers="[
            { text: 'Name', value: 'standingOrderName', searchable: true, subtitle: 1 },
            { text: 'Customer', value: 'buyer.companyName', searchable: true, title: 1 },
            { text: 'Destination', value: 'location', searchable: true, textFilter: 'toLocationLine' }]"
        navigation="standing-customer-orders"
        title="Standing Orders"
        :actualUsedHeight="175"
        useServerPagination />
</template>

<script>
export default {
    name: 'Standing-Customer-Orders-Blade',
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        getNewBladeData(bladeData) {
            return {
                agreementID: bladeData.data.agreementID
            }
        },
        getParams(bladeData) {
            return { includeDetails: false, customerIDs: bladeData.data.customerID };
        },
        getSelectBladeData(bladeData, item) {
            return { agreementID: item.supplyAgreementID };
        }
    }
}
</script>